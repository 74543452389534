// Here you can add other styles
a {
  text-decoration: none;
  color: #000248;
}
.bannerImg {
  width: 35px;
}
.logImg {
  max-width: -webkit-fill-available;
}
.editButton {
  color: #fff;
  text-decoration: none;
}
.sidebar-brand {
  background-color: #fff;
  border-right: 1px solid #ccc;
}
.sidebar {
  --cui-sidebar-bg: #000248;
}
.sidebar-nav .nav-link {
  color: #fff;
}
.sidebar-nav .nav-title {
  color: rgb(55 224 255 / 60%);
}
.sidebar-nav .nav-icon {
  color: #2a8bd5;
}
.btn-primary {
  --cui-btn-bg: #000248;
  --cui-btn-color: rgb(255, 255, 255);
  --cui-btn-bg: #000248;
  --cui-btn-border-color: #000248;
  --cui-btn-hover-color: rgba(255, 255, 255, 255);
  --cui-btn-hover-bg: rgb(55 224 255 / 60%);
  --cui-btn-hover-border-color: rgb(55 224 255 / 60%);
  --cui-btn-focus-shadow-rgb: 75, 58, 223;
  --cui-btn-active-color: rgba(255, 255, 255, 255);
  --cui-btn-active-bg: rgb(55 224 255 / 60%);
  --cui-btn-active-border-color: rgb(55 224 255 / 60%);
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: rgba(255, 255, 255, 255);
  --cui-btn-disabled-bg: #000248;
  --cui-btn-disabled-border-color: #000248;
}
.page-link.active,
.active > .page-link {
  background-color: #000248;
  border-color: #000248;
}
.card-header {
  background-color: #000248;
  border-color: #000248;
  color: #ffff;
}
.add-button {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffff;
  padding: 0px 5px;
}
thead {
  background-color: #d8edfb;
  font-size: 13px;
}
.filterbox {
  background-color: #fff;
  margin-bottom: 15px;
}
.search {
  background-color: #f3f3f3;
  border: 1px solid #e5e3e0;
  line-height: 40px;
}
.datefilter {
  background-color: #f3f3f3;
  border: 1px solid #e5e3e0;
  line-height: 40px;
}
.listfilter {
  background-color: #f3f3f3;
  border: 1px solid #e5e3e0;
  line-height: 40px;
}
.btn-export {
  background-color: #c98487;
  border: 1px solid #c98487;
  padding: 0px 5px;
}
nav {
  padding-left: 1rem;
}
.modal-body {
  // border: 4px solid #000248;
}
.modal-header {
  line-height: 15px;
}
.modal-body .heading {
  background-color: #000248;
  color: #fff;
  line-height: 25px;
  padding: 3px 5px;
  font-size: 15px;
}
.btn-close {
  background-color: #fff;
  font-size: 10px;
}


/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000248;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #258BD5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.m-0{
  margin: 0px;
}

.separator{
  height: 1px;
  background-color: #959595;
  width: 100%;
  margin: 15px 0;
  
}
.grid6x2{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: auto;
}

.styled-input{
  width: 90%;
  margin: auto;
  height: 50px;
  font-size: 20px;
  padding: 10px;
}